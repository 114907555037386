if(typeof CRD === 'undefined') {
	var CRD = {};
}

// Creates the namepsace for the object
CRD.Parallax = (function() {
	"use strict";
	
	/**
	 * Calculates a ratio value according to a given range of values. According to the scrolled amount, element position
	 * (relative to document) and viewport height, calculates the percentage of movement the parallax object sould have.
	 * The ratio value is only calculated if the element is on the visible area (for the user, according to the vieport
	 * size). If a range is provided (with some custom values) it returns the value according to the provided range
	 * (calculated with the original calculated  percentage).
	 * @param {Object} element  - Element to apply the calculations.
	 * @param {Number} scroll   - Window scrolled ammount.
	 * @param {Object} range    - Object containing min and max properties to apply as a range of values.
	 * @return {Number|Boolean}
	 * @private
	 */
	
	function compute(element, scroll, range) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var viewport = jQuery(window)
				.innerHeight(), // Viewport height
			scrolled = viewport + scroll, // Scrolled amount
			offset   = element.offset().top, // Element offset top
			height   = element.height(), // Element height
			active   = scrolled >= offset && scrolled <= offset + height + viewport, // Is user on active area?
			max      = height + viewport, // Max range for calculations
			diff, ratio, rangediff;
		
		// If user is on active area
		if(active === true) {
			
			// Computes the difference and the ratio percentage to take the value from the given range
			diff  = scrolled - offset;
			ratio = (diff * 100) / max;
			
			// if range is defined
			if(typeof range !== 'undefined' && range !== null) {
				
				// Calculates the range difference
				rangediff = range.max - range.min;
				
				// Computes the ratio for the given range
				ratio = range.min + (rangediff * (ratio / 100));
				
			}
			
			// Returns the computed ratio
			return ratio;
			
		}
		
		// ):
		return false;
		
	}
	
	/**
	 * Parallax for background images
	 * @class
	 * @param {String|Object} element - Element orselector string to apply the parallax effect.
	 * @param {Object}        options - Options to override default parallax options.
	 */
	
	function Background(element, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data('crd.parallax.background'); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Set options and events
			CRD.Utils.setOptions(this, options, this.defaults);
			
			/**
			 * Element to apply the parallax effect
			 * @property {object} element - Element
			 */
			
			this.element = element;
			
			// Initializes the object
			this.Parallax();
			
			// Stores the object reference in the element storage
			this.element.data('crd.parallax.background', this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	Background.prototype = {
		
		/**
		 * Default options
		 * @property {Object} defaults     - Default options
		 * @property {Number} defaults.css - Default CSS styles ti apply to the background container element.
		 * @property {Number} defaults.min - Minimum range of percentage to apply the movement (minimum percentage to apply
		 *                                   to background-position).
		 * @property {Number} defaults.max - Maximum range of percentage to apply the movement (maximum percentage to apply
		 *                                   to background-position).
		 */
		
		defaults : {
			css : {
				'position'            : 'relative',
				'background-position' : 'center',
				'background-repeat'   : 'no-repeat',
				'background-size'     : 'cover'
			},
			min : 0,
			max : 100
		},
		
		/**
		 * Initializes Parallax object
		 * @constructor
		 * @listen document.scroll
		 * @memberof Parallax.Background
		 * @public
		 */
		
		Parallax : function() {
			
			// Sets the element styles
			this.element.css(this.options.css);
			
			// Sets event handler function
			this.scrollHandler = function(event) {
				this.setBackgroundPosition(event.originalEvent.pageY);
			}.bind(this);
			
			// Hooks to scroll event
			jQuery(document)
				.on('scroll', this.scrollHandler);
			
			// Set parallax position
			this.setBackgroundPosition();
			
		},
		
		/**
		 * Sets the element background position according to scrolling value (from 0 to 100%).
		 * @method setBackgroundPosition
		 * @param {Number} scrolled - Window scrolled value.
		 * @memberof Parallax.Background
		 * @public
		 */
		
		setBackgroundPosition : function(scrolled) {
			
			// Variables
			var ratio = compute(this.element, scrolled || jQuery(window)
					.scrollTop(), {
				min : this.options.min,
				max : this.options.max
			}); // Computes de ratio
			
			// If ratio is defined
			if(ratio !== false) {
				
				// Sets background position
				this.element.css('background-position', '0% ' + (100 - ratio) + '%');
				
			}
			
		}
		
	};
	
	/**
	 * Parallax for block elements (using overflow hidden to mask)
	 * @class
	 * @param {String|Object} element - Element orselector string to apply the parallax effect.
	 * @param {Object}        options - Options to override default parallax options.
	 */
	
	function Element(element, options) {
		
		// Element reference
		element = jQuery(element);
		
		// Variables
		var self = element.data('crd.parallax.element'); // Data storage reference
		
		// If instant hasnt been created yet
		if(typeof self === 'undefined') {
			
			// Set options and events
			CRD.Utils.setOptions(this, options, this.defaults);
			
			/**
			 * Element to apply the parallax effect
			 * @property {object} element - Element
			 */
			
			this.element = element;
			
			// Initializes the object
			this.Parallax();
			
			// Stores the object reference in the element storage
			this.element.data('crd.parallax.element', this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	Element.prototype = {
		
		/**
		 * Default options
		 * @property {Object} defaults        - Default options
		 * @property {Number} defaults.css    - Default CSS styles to apply to the container element.
		 * @property {Number} defaults.height - Container height (relative to the element); float from 0 to 1.
		 * @property {Number} defaults.min    - Minimum range of percentage to apply the movement (minimum percentage to
		 *                                      apply to background-position).
		 * @property {Number} defaults.max    - Maximum range of percentage to apply the movement (maximum percentage to
		 *                                      apply to background-position).
		 */
		
		defaults : {
			css    : {
				'position' : 'relative',
				'overflow' : 'hidden'
			},
			height : 0.8,
			min    : 0,
			max    : 100
		},
		
		/**
		 * Initializes Parallax object
		 * @constructor
		 * @listen document.scroll
		 * @memberof Parallax.Element
		 * @public
		 */
		
		Parallax : function() {
			
			// Creates the container element
			this.container = jQuery('<div class="crd-parallax-container"/>');
			
			// Sets the container styles and height
			this.container.css(this.options.css)
				.height(this.element.height() * this.options.height);
			
			// Inserts the container, and append the element to it
			this.container.insertAfter(this.element)
				.append(this.element);
			
			// Sets event handler function
			this.scrollHandler = function(event) {
				this.setElementPosition(event.originalEvent.pageY);
			}.bind(this);
			
			// Sets event resize function
			this.resizeHandler = function(event) {
				this.update();
			}.bind(this);
			
			// Hooks to scroll and resize events
			jQuery(document)
				.on('scroll', this.scrollHandler);
			jQuery(window)
				.on('resize', this.resizeHandler);
			
			// Set parallax position
			this.setElementPosition();
			
		},
		
		/**
		 * Sets the element position according to the containing element).
		 * @method setElementPosition
		 * @param {Number} scrolled - Window scrolled value.
		 * @memberof Parallax.Element
		 * @public
		 */
		
		setElementPosition : function(scrolled) {
			
			// Variables
			var ratio = compute(this.container, scrolled || jQuery(window)
						.scrollTop(), {
					min : this.options.min,
					max : this.options.max
				}), // Computes de ratio
				offset; // Offset movement
			
			// If ratio is defined
			if(ratio !== false) {
				
				// Gets the offset diff
				offset = this.element.height() - this.container.height();
				
				// Sets the element position
				this.element.css('top', -offset + (offset * (ratio / 100)));
				
			}
			
		},
		
		/**
		 * Updates container and parallax element position
		 * @method update
		 * @memberof Parallax.Element
		 * @public
		 */
		
		update : function() {
			
			// Update container size
			this.container.css(this.options.css)
				.height(this.element.height() * this.options.height);
			
			// Update parallax position
			this.setElementPosition();
			
		}
		
	};
	
	// Define jQuery helpers
	CRD.Utils.defineHelper(Background, 'backgroundParallax', 'crd.parallax.background');
	CRD.Utils.defineHelper(Element, 'elementParallax', 'crd.parallax.element');
	
	// Retruns the object with the constructors
	return {
		Background : Background,
		Element    : Element
	};
	
})();