(function($) {
	"use strict";
	
	// Variables
	var interchange, images, actions;
	
	// Interchange
	interchange = new CRD.Interchange({
		selector : '.interchange'
	});
	
	// AllImagesLoaded
	images = new CRD.AllImagesLoaded(document.body, { background : true });
	
	// Hooks to AllImagesLoaded
	$(images).on('crd.allimagesloaded.default', function() {
		
		// Initializas BreakpointActions
		actions = new CRD.BreakpointActions(interchange);
		
		// Hooks new event to AllImagesLoaded
		$(images).on('crd.allimagesloaded.default', function() {
			
			// Triggers BreakpointActions (to update click instances, parallax)
			actions.switchActions(true, interchange.breakpoint.current);
			
		});
		
	}.once()); // First event will trigger only once
	
	// Hooks to Interchange
	$(interchange).on('crd.interchange.after', function() {
		
		// Trigger AllImagesLoaded
		images.start();
		
	});
	
	// Parallax instances
	$('.parallax').backgroundParallax({ min : 40, max : 60 });
	
})(jQuery);