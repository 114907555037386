if(typeof CRD === 'undefined') {
	var CRD = {};
}

(function() {
	"use strict";
	
	/**
	 * AllImagesLoaded
	 * @class
	 * @param {Object} [options] - Options to override default spy options.
	 * @return {CRD.AllImagesLoaded}
	 */
	
	CRD.AllImagesLoaded = function(element, options) {
		
		// Element reference (body is used as default)
		element = jQuery(element || document.body);
		
		// Variables
		var self = element.data('crd.allimagesloaded'); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			// Set options and events
			CRD.Utils.setOptions(this, options, this.defaults);
			
			// Set element
			this.element = element;
			
			// Init
			this.AllImagesLoaded();
			
			// Stores the object reference in the element storage
			this.element.data('crd.allimagesloaded', this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	};
	CRD.AllImagesLoaded.prototype = {
		
		/**
		 * Default options
		 * @property {Object}  defaults            - Default options
		 * @property {String}  defaults.selector   - Selector to match elements to watch
		 * @property {Boolean} defaults.background - Watch for background images?
		 */
		
		defaults : {
			selector   : '*',
			background : false
		},
		
		/**
		 * Control CSS classes definition
		 * @property {Object} classes                 - CSS classes definition
		 * @property {String} classes.imgpreload      - Images to preload
		 * @property {String} classes.bgpreload       - Background iamges to preload
		 * @property {String} classes.bgpreloadholder - Background iamges reload element holder
		 * @property {String} classes.imgloaded       - Image preloaded successfully
		 * @property {String} classes.imgerror        - Image preloaded with errors
		 */
		
		classes : {
			imgpreload      : 'js-img-preload',
			bgpreload       : 'js-bg-preload',
			bgpreloadholder : 'js-bg-holder',
			imgloaded       : 'js-img-loaded',
			imgerror        : 'js-img-error'
		},
		
		/**
		 * Element to seach for images
		 * @type {Object}
		 * @defaultvalue
		 */
		
		element : document,
		
		/**
		 * Collection of images
		 * @type {Array}
		 * @defaultvalue
		 */
		
		elements : [],
		
		/**
		 * Array of sources to load (prevents duplicated sources)
		 * @type {Array}
		 * @defaultvalue
		 */
		
		sources : [],
		
		/**
		 * Event handler (load event)
		 * @type {Function}
		 * @defaultvalue
		 */
		
		loadHandler : null,
		
		/**
		 * Event handler (error event)
		 * @type {Function}
		 * @defaultvalue
		 */
		
		errorHandler : null,
		
		/**
		 * Initializes AllImagesLoaded object
		 * @constructor
		 * @memberof AllImagesLoaded
		 */
		
		AllImagesLoaded : function() {
			
			// Initializes image loading and hooks
			this.start();
			
		},
		
		/**
		 * Initializes image loading and hooks
		 * @method start
		 * @return {CRD.AllImagesLoaded}
		 * @memberof AllImagesLoaded
		 * @public
		 */
		
		start : function() {
			
			// Variables
			var replace = []; // Elements to replace (replaces elements object collection with elements hooked to the object)
			
			// Resets all image and backgrounds states and hooks
			this.reset();
			
			// Get all elements to perform the search (images and background images)
			this.elements = jQuery(this.options.selector, this.element);
			
			// Defines handler for load success
			this.loadHandler = function(event) {
				this.imageLoaded(event);
			}.bind(this);
			
			// Defines handler for load error
			this.errorHandler = function(event) {
				this.imageError(event);
			}.bind(this);
			
			// For each element matched by CSS selector
			this.elements.each(function(i, e) {
				
				// Variables
				var element = jQuery(e), // Referente to element
					src     = element.attr('src'), // Source (for images)
					img; // Preloading image object
				
				// If the element is visible (otherwise it wont load)
				if(element.is(':visible')) {
					
					// If matched element is an image, and the source is not duplicated
					if(e.tagName === 'IMG' && jQuery.inArray(src, this.sources) === -1) {
						
						// Element trasnformations
						element
							.attr('src', '') // Remove source (to prevent loading)
							.addClass(this.classes.imgpreload) // Add control class name
							.bind({
								'load'  : this.loadHandler, // Bind load success
								'error' : this.errorHandler // Bind load error
							})
							.attr('src', src); // Adds source to start loading or to trigger load/error event (to prevent cache related problems)
						
						// Add the element to the collection of eleents
						replace.push(e);
						
						// Add source to sources boing loaded by the object
						this.sources.push(src);
						
					}
					
					// If matched element is not an image, and has a background image, and background image loading is enabled
					else if(this.options.background === true &&
						(e.currentStyle && e.currentStyle.backgroundImage !== 'none') ||
						(document.defaultView.getComputedStyle(e, null).getPropertyValue('background-image') !== 'none')) {
						
						//Get background image source
						src = e.currentStyle ?
							e.currentStyle.backgroundImage :
							document.defaultView.getComputedStyle(e, null).getPropertyValue('background-image');
						
						// Removes CSS source definition
						src = src.replace(/(^url\(("|')?)|(("|')?\)$)/gi, '');
						
						// If source is not duplicated
						if(jQuery.inArray(src, this.sources) === -1) {
							
							// Element trasnformations
							element.addClass(this.classes.bgpreload); // Adds control class
							
							// Creates an specific element to preload images (background image loading can not be hooked with javascript)
							img = jQuery('<img>')
								.addClass(this.classes.bgpreloadholder) // Adds control class
								.data('related', e) // Stores reference to original element
								.attr('src', src) // Sets image source
								.bind({
									'load'  : this.loadHandler, // Bind load success
									'error' : this.errorHandler // Bind load error
								});
							
							// Add the element to the collection of eleents
							replace.push(e);
							
							// Add source to sources boing loaded by the object
							this.sources.push(src);
							
						}
						
					}
					
				}
				
			}.bind(this));
			
			// Replace element colletion with elements hooked with the object
			this.elements = replace;
			
			// (:
			return this;
			
		},
		
		/**
		 * Resets all elements
		 * @method reset
		 * @return {CRD.AllImagesLoaded}
		 * @memberof AllImagesLoaded
		 * @public
		 */
		
		reset : function() {
			
			// Variables
			var classes   = [], // Array of classes
				selectors = [], // Array of CSS selectors
				c;
			
			// For each defines class
			for(c in this.classes) {
				
				// Filter unwanter properties
				if(this.classes.hasOwnProperty(c)) {
					
					// Adds classes and CSS selectors
					classes.push(this.classes[c]);
					selectors.push('.' + this.classes[c]);
					
				}
				
			}
			
			// Reset all sources
			this.sources = [];
			
			// Reset all states
			jQuery(selectors.join(', ')).removeClass(classes.join(' '));
			
			// (:
			return this;
			
		},
		
		/**
		 * Handles images loading (success)
		 * @method imageLoaded
		 * @param {Object} event - Image loaded evnt
		 * @memberof AllImagesLoaded
		 * @public
		 */
		
		imageLoaded : function(event) {
			
			// Variables
			var element = jQuery(event.target), // Reference to loaded element
				related; // Related element (for background images)
			
			// If event was triggered by a holder (to load background images)
			if(element.hasClass(this.classes.bgpreloadholder)) {
				
				// Defines related element
				related = jQuery(element.data('related'));
				
				// Sets/removes control classes to related element
				related
					.removeClass(this.classes.bgpreload)
					.addClass(this.classes.imgloaded);
				
				// Remove element from DOM
				element.remove();
				
			}
			
			// If event was triggered by an image
			else {
				
				// Sets/removes control classes
				element
					.removeClass(this.classes.imgpreload)
					.addClass(this.classes.imgloaded);
				
			}
			
			// Eval results
			this.evalResults();
			
		},
		
		/**
		 * Handles images loading (error)
		 * @method imageError
		 * @param {Object} event - Image loaded evnt
		 * @memberof AllImagesLoaded
		 * @public
		 */
		
		imageError : function(event) {
			
			// Variables
			var element = jQuery(event.target), // Reference to loaded element
				related; // Related element (for background images)
			
			// If event was triggered by a holder (to load background images)
			if(element.hasClass(this.classes.bgpreloadholder)) {
				
				// Defines related element
				related = jQuery(element.data('related'));
				
				// Sets/removes control classes to related element
				related
					.removeClass(this.classes.bgpreload)
					.addClass(this.classes.imgerror);
				
				// Remove element from DOM
				element.remove();
				
			}
			else {
				
				// Sets/removes control classes
				element
					.removeClass(this.classes.imgpreload)
					.addClass(this.classes.imgerror);
				
			}
			
			// Eval results
			this.evalResults();
			
		},
		
		/**
		 * Check all images being loaded, to check if load action has been completed
		 * @method evalResults
		 * @fires crd.allimagesloaded.default
		 * @fires crd.allimagesloaded.success
		 * @fires crd.allimagesloaded.error
		 * @return {CRD.AllImagesLoaded}
		 * @memberof AllImagesLoaded
		 * @public
		 */
		
		evalResults : function() {
			
			// Variables
			var success = jQuery('.' + this.classes.imgloaded), // Loaded images
				error   = jQuery('.' + this.classes.imgerror); // Images not loaded
			
			// If all images completed load sequence (with or w/o error)
			if(success.length + error.length === this.elements.length) {
				
				// If all images has been loaded (no errors)
				if(success.length === this.elements.length) {
					
					/**
					 * Triggers events
					 * @event crd.allimagesloaded.success
					 */
					
					jQuery(this)
						.trigger('crd.allimagesloaded.success', [
							success,
							[],
							this
						]);
					
				}
				
				// If some errors ocurred
				else {
					
					/**
					 * Triggers events
					 * @event crd.allimagesloaded.error
					 */
					
					jQuery(this)
						.trigger('crd.allimagesloaded.error', [
							success,
							error,
							this
						]);
					
				}
				
				/**
				 * Triggers events
				 * @event crd.allimagesloaded.default
				 */
				
				jQuery(this)
					.trigger('crd.allimagesloaded.default', [
						success,
						error,
						this
					]);
				
				// Resets all elements
				this.reset();
				
			}
			
			// (:
			return this;
			
		}
		
	};
	
})();