if(typeof CRD == 'undefined') {
	var CRD = {};
}

/**
 * MobileNav
 * @param {Object|String} fixed     - Fixed bar element
 * @param {Object|String} trigger   - Trigger element
 * @param {Object|String} container - Container element
 * @param {Object}        options   - Options for the FixedNav instance
 * @class
 */

CRD.MobileNav = function(fixed, trigger, container, options) {
	
	// Variables
	var element = jQuery(fixed), // Element reference
		self = element.data('crd.mobilnav'); // Data storage reference
	
	// If instance hasn't been created yet
	if(typeof self === 'undefined') {
		
		// Apply base constructor
		CRD.FixedNav.apply(this, [fixed, options]);
		
		// Sets the trigger and the container
		this.trigger = jQuery(trigger);
		this.container = jQuery(container);
		
		// Initializes the object (after the parent initialization, so this.fixed is present in the object)
		this.MobileNav();
		
		// Stores the object reference in the element storage
		this.fixed.data('crd.mobilenav', this);
		
		// Sets data reference
		self = this;
		
	}
	
	// Returns object reference
	return self;
	
};

// Inherits from CRD.FixedNav
CRD.MobileNav.inherit(CRD.FixedNav);

// Extends prototype with own functions
CRD.MobileNav.prototype = jQuery.extend(CRD.MobileNav.prototype, {
	
	/**
	 * Initialized mobile navigation.
	 * @constructor
	 */
	
	MobileNav : function() {
		
		// Creates the event handler
		this.clickHandler = function() {
			this.toggleNav();
		}.bind(this);
		
		// Adds the evento to the trigger
		this.trigger.on('click', this.clickHandler);
		
	},
	
	/**
	 * Toggles navigation open/close.
	 * @method toggleNav
	 * @fires crd.mobilenav.open
	 * @fires crd.mobilenav.close
	 * @memberOf MobileNav
	 * @public
	 */
	
	toggleNav : function() {
		
		// Variables
		var event = this.container.is(':visible') ? 'close' : 'open', // Default event to trigger
			overflow = event === 'close' ? 'visible' : 'hidden'; // Default overflow state
			
		// Change overflow state
		jQuery(document.body).css('overflow', overflow);
		
		// Toggles container visibility
		this.container.fadeToggle();
		
		/**
		 * Triggers event
		 * @event crd.mobilenav.open
		 * @event crd.mobilenav.close
		 */
		
		jQuery(this)
			.trigger('crd.mobilenav.' + event, [
				this.trigger,
				this.container,
				this
			]);
		
	},
	
	/**
	 * Destoys the object and unhooks the events
	 * @method destroy
	 * @memberOf MobileNav
	 * @public
	 */
	
	destroy : function() {
		
		// Unhooks FixedNav and ScrollSpy events
		CRD.FixedNav.prototype.destroy.apply(this);
		
		// Change overflow state
		jQuery(document.body).css('overflow', 'visible');
		
		// Removes the event from the trigger
		this.trigger.off('click', this.clickHandler);
		
		// Removes the object reference from element storage
		this.fixed.removeData('crd.mobilenav');
		
	}
	
});