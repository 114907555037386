CRD.Interchange.prototype.gatherData = function(element) {
	"use strict";
	
	// Element
	element = jQuery(element);
	
	// Variables
	var dataprefix    = 'image-', // Data attribute prefix
		normalization = {
			'xs' : 'mobile',
			'sm' : 'tablet',
			'md' : 'desktop',
			'lg' : 'desktop-large'
		}, // Data normalization
		data          = {}, // Gathered data
		value; // Value for gathered data
	
	// For each breakpoint
	for(var i in normalization) {
		
		// Filter unwanter properties
		if(normalization.hasOwnProperty(i)) {
			
			// Get the data value
			value = element.data(dataprefix + normalization[i]);
			
			// If data exists
			if(typeof value !== 'undefined') {
				
				// Sets image source for breakpoint
				data[i] = value;
				
			}
			
		}
		
	}
	
	// Returns data object
	return data;
	
};