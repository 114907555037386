if(typeof CRD === 'undefined') {
	var CRD = {};
}

// Creates the namepsace for the object
CRD.ScrollSpy = (function() {
	"use strict";
	
	// Variables
	var constants = {
		Scroll : {
			UP   : 'up',
			DOWN : 'down'
		}
	}; // Constants
	
	/**
	 * Gets the maximum scrolling value for the element.
	 * @param {Object} element - Scrollable element (window or any other element)
	 * @return {Number}
	 * @private
	 */
	
	function getMaxScroll(element) {
		
		// Sets the element
		element = jQuery(element);
		
		// Returns the maximun scrolling value
		return element.is(jQuery(window)) || element.is(jQuery(document)) ?
			jQuery(document).height() - jQuery(window).height() : // Window maximum scrolling value
			element.prop('scrollHeight') - element.outerHeight(); // Element maximum scrolling value
		
	}
	
	/**
	 * ScrollSpy
	 * @class
	 * @param {String|Object} element - Element or selector string to spy.
	 */
	
	function ScrollSpy(element) {
		
		// Element reference (window is used as default)
		element = jQuery(element || window);
		
		// Variables
		var self = element.data('crd.scrollspy'); // Data storage reference
		
		// If instance hasn't been created yet
		if(typeof self === 'undefined') {
			
			/**
			 * Events stack
			 * @property {Array} events - Array of events to fire
			 */
			
			this.events = [];
			
			/**
			 * Last scroll position
			 * @type {Number}
			 * @defaultvalue
			 */
			
			this.lastScrollY = 0;
			
			/**
			 * Scrolling direction
			 * @type {String}
			 * @defaultvalue
			 */
			
			this.scrollDirection = constants.Scroll.DOWN;
			
			/**
			 * Element to use as fixed navigation bar
			 * @property {object} element - Element
			 */
			
			this.element = element;
			
			// Initializes the object
			this.ScrollSpy();
			
			// Stores the object reference in the element storage
			this.element.data('crd.scrollspy', this);
			
			// Sets data reference
			self = this;
			
		}
		
		// Returns object reference
		return self;
		
	}
	
	ScrollSpy.prototype = {
		
		/**
		 * Modifiers for size calculations
		 * @property {Object}   modifiers          - Modifiers container
		 * @property {Function} modifiers.%        - Modifier for percentage values
		 * @property {Function} modifiers.viewport - Modifier viewport based values
		 */
		
		modifiers : {
			'%'        : function(total, value) {
				return total * (value / 100);
			},
			'viewport' : function(total, value) {
				return jQuery(window)
						.height() * value;
			},
		},
		
		/**
		 * Initializes ScrollSpy object
		 * @constructor
		 * @listen this.element.scroll
		 * @memberof ScrollSpy
		 */
		
		ScrollSpy : function() {
			
			// Create handlers for window scrolling event
			this.scrollHandler = function() {
				this.evalScrollPosition();
			}.bind(this);
			
			// Hooks to element scrolling event
			jQuery(this.element)
				.scroll(this.scrollHandler);
			
		},
		
		/**
		 * Evaluates scroll position
		 * @method evalScrollPosition
		 * @memberof ScrollSpy
		 * @public
		 */
		
		evalScrollPosition : function() {
			
			// Variables
			var size   = getMaxScroll(this.element), // Element scrollable area
				scroll = this.element.scrollTop(), // Offset scroll
				position; // Parsed position
			
			// For each event defined
			for(var x = 0, max = this.events.length; x < max; x++) {
				
				// If events hasn't been fired yet and it not a one time event
				if(this.events[x].fired === false || this.events[x].once === false) {
					
					// Parse position
					position = this.parsePosition(this.events[x].position, size);
					
					// If position is beyond scroll
					if((position !== false && scroll >= position) || this.events[x].position === '*') {
						
						// Fires event
						this.events[x].callback();
						
						// Sets event as fired
						this.events[x].fired = true;
						
					}
					
				}
				
			}
			
			// Evals scrollind direction
			this.scrollDirection = this.lastScrollY >= scroll ?
				constants.Scroll.UP :
				constants.Scroll.DOWN;
			
			// Last scroll position (mobile and safari fx negative values fix)
			this.lastScrollY = Math.max(0, scroll);
			
			/**
			 * Triggers events
			 * @event crd.scrollspy.up
			 * @event crd.scrollspy.down
			 * @event crd.scrollspy.scroll
			 */
			
			jQuery(this)
				.trigger('crd.scrollspy.' + this.scrollDirection, [
					this.lastScrollY,
					this
				]);
			
			jQuery(this)
				.trigger('crd.scrollspy.scroll', [
					this.lastScrollY,
					this.scrollDirection
				]);
			
		},
		
		/**
		 * Evaluates scroll position
		 * @method at
		 * @param {String|Number} at       - Position to look out in scroll event (int, %, px or any defined modifier).
		 * @param {Function}      callback - Callback to execute.
		 * @param {Boolean}       once     - Callback must be executed only once?
		 * @memberof ScrollSpy
		 * @public
		 */
		
		at : function(position, callback, once) {
			
			// Adds event to fire at certain scrolling position
			this.events.push({
				'position' : position,
				'callback' : callback || jQuery.empty,
				'once'     : once || false,
				'fired'    : false
			});
			
			// Returns reference to self in order to beign able to chain funcion calls
			return this;
			
		},
		
		/**
		 * Parse scrolling position and returns number, accepts int, % or px units
		 * @method parsePosition
		 * @param {String|Number} position - Position to convert to int
		 * @param {Number}        size     - Current scrollable area
		 * @returns {Number|Boolean}
		 * @memberof ScrollSpy
		 * @public
		 */
		
		parsePosition : function(position, size) {
			
			// Variables
			var parsed = position, // Parsed value
				unit   = false, // Value units
				result = false;
			
			// If defined position is a number
			if(isNaN(parseFloat(position)) || !isFinite(position)) {
				
				// Parse number and unit
				parsed = parseFloat(position);
				unit   = position.replace(parsed, '').trim();
				
				// If modifier is defined
				if(this.modifiers.hasOwnProperty(unit)) {
					
					// gets modified value
					result = this.modifiers[unit](size, parsed);
					
				}
				else {
					
					// Everything else is a int
					result = parsed;
					
				}
				
			}
			else {
				
				// Supplyed number
				result = Number(position);
				
			}
			
			// Negative value
			if(parsed < 0) {
				
				// Substracts value to the result
				result = size + result;
				
			}
			
			// (:
			return result;
			
		},
		
		/**
		 * Add modifiers to an specific instance or to the global ScrollSpy constructor.
		 * @method addModifier
		 * @param {String}   keyword  - Keyword to find in parsed measurement string (Example: '40%').
		 * @param {Function} callback - Callback to execute for the defined keyword.
		 * @memberof ScrollSpy
		 * @public
		 */
		
		addModifier : function(keyword, callback) {
			
			// Variables
			var scope = this instanceof ScrollSpy ? // Target is this or function prototype?
				this :
				ScrollSpy.prototype;
			
			// If the modifier is not defined
			if(scope.modifiers.hasOwnProperty(keyword) === false) {
				
				// Add the modifier
				scope.modifiers[keyword] = callback;
				
			}
			
			// (:
			return scope;
			
		},
		
		/**
		 * Destoys the object and unhooks the events
		 * @method destroy
		 * @memberOf ScrollSpy
		 * @public
		 */
		
		destroy : function() {
			
			// Hooks to element scrolling event
			jQuery(this.element)
				.off('scroll', this.scrollHandler);
			
			// Removes the object reference from element storage
			this.element.removeData('crd.scrollspy');
			
		}
		
	};
	
	// Set object constants
	CRD.Utils.setConstants(ScrollSpy, constants);
	
	// Retruns the constructor
	return ScrollSpy;
	
})();