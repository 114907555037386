// variables namespace
if(typeof CRD === 'undefined') {
	var CRD = {};
}

(function() {
	"use strict";
	
	CRD.BreakpointActions = function(spy) {
		
		// Initializes
		this.BreakpointActions(spy);
		
	};
	
	CRD.BreakpointActions.prototype = {
		
		// handlers
		switchHandler : null,
		
		// constructor
		BreakpointActions : function(spy) {
			
			// referencia al objeto
			var self = this;
			
			// crea el handler para el resize
			this.switchHandler = function(e, changed, breakpoint) {
				self.switchActions(changed, breakpoint);
			};
			
			// setea los eventos
			jQuery(spy !== null ? spy : new CRD.BreakpointSpy())
				.on('crd.breakpointspy.always', this.switchHandler);
			
			// Perform actions
			this.switchActions(true, spy.breakpoint.current);
			
		},
		
		// ejecuta las accciones especificas 8al cambio de breakpoint)
		switchActions : function(changed, breakpoint) {
			
			// Variables
			var home = jQuery(document.body).hasClass('home'); // Home page?
			
			// tamaño de fuente proporcional
			(function() {
				var width = Math.min(1440, jQuery('body')
						.width()),
					coef;
				switch(breakpoint) {
					case 'sm':
						coef = 0.015;
						break;
					case 'md':
						coef = 0.013;
						break;
					case 'lg':
						coef = 0.0115;
						break;
					default:
						coef = 0.04;
						break;
				}
				jQuery('body, html')
					.css('font-size', (width * coef) + 'px');
			})();
			
			// offset top para la nav mobile y el slider de ícons (para que la nav no "pise" los íconos)
			(function() {
				var offset, selector;
				switch(breakpoint) {
					case 'xs':
						offset = jQuery('#header').height();
						selector = home === true ?
							', #icon-nav > div' :
							', #nav-desktop + div';
						jQuery('#nav-mobile > div' + selector).css('padding-top', offset);
						break;
					default:
						jQuery('#icon-nav > div, #nav-desktop + div')
							.css('padding-top', 0);
						break;
				}
			})();
			
			// Solo cuando cambia el breakpoint
			if(changed === true) {
				
				// nav mobile/desktop (hace el switch dependiendo del breakpoint)
				(function() {
					var mobile = jQuery('#header'),
						desktop = jQuery('#nav-desktop'),
						nav, offset;
					switch(breakpoint) {
						case 'xs':
							if(typeof desktop.fixednav('get') !== 'undefined') {
								desktop.fixednav('destroy');
							}
							if(typeof mobile.data('crd.mobilenav') === 'undefined') {
								nav = new CRD.MobileNav(mobile, '#header .nav-container', '#nav-mobile', { initState : CRD.FixedNav.Mode.OPEN });
								jQuery(nav).on({
									'crd.mobilenav.open'  : function(e, trigger) {
										jQuery(trigger).addClass('open');
									},
									'crd.mobilenav.close' : function(e, trigger) {
										jQuery(trigger).removeClass('open');
									}
								});
							}
							break;
						default:
							if(typeof mobile.data('crd.mobilenav') !== 'undefined') {
								mobile.data('crd.mobilenav').destroy();
							}
							if(typeof desktop.fixednav('get') === 'undefined') {
								offset = home === true ?
									function() {
										var icons = jQuery('#icon-nav');
										return icons.offset().top + icons.height();
									} :
									function() {
										var header = jQuery('#header');
										return header.offset().top + header.height();
									};
								desktop.fixednav({
									initState    : CRD.FixedNav.Mode.CLOSE,
									hideOnScroll : false,
									offset       : {
										top : offset
									}
								});
							}
							break;
					}
				})();
				
				// slider iconos
				(function() {
					var element = jQuery('#icon-nav .slider');
					switch(breakpoint) {
						case 'xs':
							if(element.hasClass('slick-initialized')) {
								element.slick('refresh');
							}
							else {
								element
									.slick({
										dots           : false,
										arrows         : true,
										infinite       : false,
										speed          : 300,
										autoplay       : false,
										adaptiveHeight : true
									});
							}
							break;
						default:
							if(element.hasClass('slick-initialized') && element.slick('getSlick')) {
								element.slick('unslick');
							}
							break;
					}
				})();
				
				// slider principal
				(function() {
					var element = jQuery('#slider .row');
					switch(breakpoint) {
						case 'sm':
						case 'md':
						case 'lg':
							if(element.hasClass('slick-initialized')) {
								element
									.slick('refresh')
									.elementParallax('update');
							}
							else {
								element
									.slick({
										dots           : false,
										arrows         : true,
										infinite       : true,
										speed          : 300,
										autoplay       : true,
										adaptiveHeight : true
									})
									.elementParallax();
							}
							break;
					}
				})();
				
				// slider news
				(function() {
					var element = jQuery('#news .slider');
					switch(breakpoint) {
						case 'xs':
							if(element.hasClass('slick-initialized')) {
								element.slick('refresh');
							}
							else {
								element
									.slick({
										dots           : false,
										arrows         : true,
										infinite       : false,
										speed          : 300,
										autoplay       : false,
										adaptiveHeight : true
									});
							}
							break;
						default:
							if(element.hasClass('slick-initialized') && element.slick('getSlick')) {
								element.slick('unslick');
							}
							break;
					}
				})();
				
			}
			
			// (:
			return this;
			
		}
		
	};
	
})();