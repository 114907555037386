if(typeof CRD === 'undefined') {
	var CRD = {};
}

(function() {
	"use strict";
	
	// Utilities namespace
	CRD.Utils = {
		
		/**
		 * Set object or class options in Object.options property. If the defined options has an 'on' property, it will
		 * bind all the defined events as object or class events. The 'on' property must contain a set of key and values,
		 * where key is the event name and the value contains the callback function. All callback will be automatically
		 * binded to the object.
		 * @param {Function|Object} object   - Object where options will be setted.
		 * @param {Object}          options  - Object containing options to set.
		 * @param {Object}          defaults - Default options for the object or class.
		 * @return {Object|Function|Class}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		setOptions : function(object, options, defaults) {
			
			// Variables
			var events;
			
			// If defined options have an 'on' property (reserved for events)
			if(typeof options === 'object' && options.hasOwnProperty('on') && typeof options.on === 'object') {
				
				// Store events in a variable
				events = options.on;
				
				// Removes reserved property from options
				delete options.on;
				
				// For each defined event
				for(var i in events) {
					
					// Filter unwanted properties and check for event to trigger only a function
					if(events.hasOwnProperty(i) && typeof events[i] === 'function') {
						
						// Set the defined event and binds the event to the current object or class
						jQuery(object).on(i, events[i].bind(object));
						
					}
					
				}
				
			}
			
			// Set object or class options; it will use any prior object options (if defined), default or empty object
			object.options = jQuery.extend({}, object.options || defaults || {}, options);
			
			// (:
			return object;
			
		},
		
		/**
		 * Define constants for an object, function or class, using Object.defineProperty
		 * @param {Object|Function|Class} object - Object or function where the constants will be stored
		 * @param {Object} constants             - Constants object
		 * @memberof CRD.Utils
		 * @public
		 */
		
		setConstants : function(object, constants) {
			
			// For each defined constant
			for(var c in constants) {
				
				// Filter unwanter properties
				if(constants.hasOwnProperty(c)) {
					
					switch(typeof constants[c]) {
						
						// If value is an object
						case 'object':
							
							// Creates the object
							object[c] = {};
							
							// Create nested constants
							CRD.Utils.setConstants(object[c], constants[c]);
							
							break;
							
						// Default
						default:
							
							// Sets setter and getter for the object
							Object.defineProperty(object, c, {
								value : constants[c]
							});
							
							break;
						
					}
					
				}
				
			}
			
		},
		
		/**
		 * Defines helpers to create Class or Function instances on the fly using jQuery selectors. This function is
		 * coded to work with the class definition proposed in other object of the CRD Library.
		 * @param {Object|Function|Class} object  - Obect that the helper will create
		 * @param {String}                helper  - Helper name (to use as jQuery(selecto).helper(arguments))
		 * @param {String}                storage - Storage name; by default it will be the same name as the helper
		 * @return {Object|Function|Class|null}
		 * @memberof CRD.Utils
		 * @public
		 */
		
		defineHelper : function(object, helper, storage) {
			
			// Defines storage name
			storage = storage || helper;
			
			// Creates the jQuery helper function
			jQuery.fn[helper] = function() {
				
				// Variables
				var args = Array.prototype.slice.call(arguments), // Function arguments
					instance, constructor, result;
				
				// For each matched element
				for(var x = 0, max = this.length; x < max; x++) {
					
					// Try to get the instance
					instance = jQuery(this).data(storage);
					
					// if first argument is an object (options)
					if(typeof instance === 'undefined') {
						
						// Creates the new instance intermetiate function (binded to the object prototype)
						constructor = object.bind(object.prototype, jQuery.merge([this], args));
						
						// Initialized and stores the object
						jQuery(this).data(storage, new constructor());
						
					}
					else {
						
						// if methos is the keywork 'get', then it will try to get the instance
						if(args[0] === 'get') {
							
							// Retruns the object from the object storage or undefined
							return instance;
							
						}
						else {
							
							// Applys the method
							result = object[args[0]].apply(object, args.slice(1));
							
							// If function returns something
							if(typeof result !== 'undefined') {
								
								// Returns the returned value
								return result;
								
							}
							
						}
						
					}
					
				}
				
			};
			
		}
		
	};
	
})();