if(typeof CRD !== 'undefined' && CRD.hasOwnProperty('BreakpointSpy')) {
	
	CRD.BreakpointSpy.prototype.addAllThese([
		
		// Default set
		[
			'default',
			'(min-width: 1px)'
		],
		[
			'xs',
			'(max-width: 767px)'
		],
		[
			'sm',
			'(min-width: 768px)'
		],
		[
			'md',
			'(min-width: 992px)'
		],
		[
			'lg',
			'(min-width: 1200px)'
		]
	
	]);
	
}